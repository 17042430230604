import React from "react"
import ImageInPage from "../../wal-common/src/components/Image/ImageInPage/ImageInPage"
import Title from "../Title/Title"
import HTMLContent from "../../wal-common/src/components/HTMLContent/HTMLContent"
import './IndexPage.scss'

function IndexPage(props) {
  return <div className="IndexPage">
    <ImageInPage file={props.file} align="left" copyright={props.imageCopyright} />
    <div className="is-hidden-mobile">
      <Title>{props.title}</Title>
    </div>
    <div className="is-hidden-tablet">
      <Title level={3}>{props.title}</Title>
    </div>
    <HTMLContent html={props.html} className="IndexPage__Content"/>
  </div>
}

export default IndexPage
