import { graphql } from "gatsby"
import React from 'react'
import Layout from "../components/layout"
import IndexPage from "../components/IndexPage/IndexPage"

const IndexPageTmp = ({data: { markdownRemark: {htmlAst, frontmatter: {image, title, imageCopyright}}}}) => (
  <Layout>
    <IndexPage file={image} imageCopyright={imageCopyright} title={title} html={htmlAst} />
  </Layout>
)

export default IndexPageTmp

export const pageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        image
        imageCopyright  
        title
      }
    }
  }
`

